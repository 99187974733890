<script lang="ts" setup>
import { PropType } from 'vue'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'
import { EmitsEnum } from '~~/src/constants/emits'
import { useAccommodationsStore } from '~~/src/store/accommodations'
import { ISelectable } from '~~/src/types/Selectable'

const props = defineProps({
	disabled: Boolean,
	getChild: { type: Function as PropType<(roomId: number) => any>, default: () => undefined },
	getChildProps: { type: Function as PropType<(pos: number) => any>, default: () => undefined },
	getCheckboxProps: { type: Function as PropType<(pos: number) => any>, default: () => {} },
	singleSelectionMode: { type: Boolean, default: false },
	accommodationId: Number,
	selectAllAllowed: { type: Boolean, default: true },
	searchTerm: { type: String, default: undefined },
	selectedIds: { type: Object as PropType<Number[]>, default: [] },
	maximumSelectionsReached: { type: Boolean, default: false },
	testIds: {
		type: Object as PropType<{
			'accommodation-dropdown'?: string
			'search-input'?: string
			'room-type-list'?: string
			'select-all'?: string
			'tweak-left-button'?: string
			'tweak-right-button'?: string
			'remover-modifier-container'?: string
			'filters-container'?: string
		}>,
		default: () => ({}),
	},
})
const {
	disabled,
	getChild,
	getChildProps,
	getCheckboxProps,
	singleSelectionMode,
	accommodationId,
	selectAllAllowed,
	selectedIds,
	maximumSelectionsReached,
	searchTerm,
	testIds,
} = toRefs(props)

const editableSelectedIds = ref([...selectedIds.value])
watch(selectedIds, () => (editableSelectedIds.value = selectedIds.value))
const getRoomTypesDefaults = computed(() => (accommodationId: number): ISelectable<TranslationKey, any>[] => {
	const currentAccommodation = accommodationStore.accommodations.find((element) => element.id === accommodationId)

	const defaults =
		currentAccommodation?.roomTypes?.map((room) => ({
			element: room.name as TranslationKey,
			selected: false,
			id: room.id,
			disabled: false,
			...getCheckboxProps.value(room.id),
		})) || []

	return defaults.map((el) => ({ ...el, selected: editableSelectedIds.value.some((i) => i == el.id) }))
})

const emit = defineEmits([EmitsEnum.Change, EmitsEnum.Select])

const accommodationStore = useAccommodationsStore()
const selectedAccommodation = ref(accommodationId?.value || accommodationStore.accommodations[0].id)
watch([accommodationId], () => (selectedAccommodation.value = accommodationId?.value || selectedAccommodation.value))
const selectedRoomTypes = ref(getRoomTypesDefaults.value(accommodationId?.value!))
const readableRoomTypes = computed(() =>
	selectedRoomTypes.value
		.filter((el) =>
			searchTerm?.value
				? accommodationStore.getRoomTypeById(el.id)!.name.toLowerCase().includes(searchTerm?.value)
				: true
		)
		.map((el) => ({
			...el,
			element: accommodationStore.getRoomTypeById(el.id)!.name,
		}))
) as any

watch(accommodationId!, (newVal) => {
	selectedRoomTypes.value = getRoomTypesDefaults.value(newVal!)
	editableSelectedIds.value = []
})

const onRoomTypeChange = (newVal: ISelectable<TranslationKey, number>[]) => {
	selectedRoomTypes.value = newVal
	editableSelectedIds.value = [...newVal.filter((el) => el.selected).map((el) => el.id)]

	emit(EmitsEnum.Change, editableSelectedIds.value, accommodationId?.value)
}

const checkboxListRef = ref()
const onSelectAllChange = (status: boolean) => checkboxListRef.value.toggleAll(status)
const areAllRoomTypesSelected = computed(() => editableSelectedIds.value.length === selectedRoomTypes.value.length)
const anyRoomTypeSelected = computed(
	() => editableSelectedIds.value.length > 0 && editableSelectedIds.value.length < selectedRoomTypes.value.length
)

watch(selectedIds, () => {
	selectedRoomTypes.value = selectedRoomTypes.value.map((el) => ({
		...el,
		selected: selectedIds.value.includes(el.id),
	}))
})
</script>

<template>
	<div class="flex flex-col gap-2">
		<CommonCheckbox
			v-if="!disabled && !singleSelectionMode && selectAllAllowed"
			:text="TranslationKeys.SELECT_ALL"
			:clicked="areAllRoomTypesSelected"
			:indeterminate="anyRoomTypeSelected"
			@toggle="onSelectAllChange"
			checkbox-class="px-4 py-4"
			:data-testid="testIds?.['select-all']"
		>
		</CommonCheckbox>
		<AnimationCollapse v-if="!singleSelectionMode">
			<CommonCheckboxList
				data-id="checkbox-room-select-row"
				ref="checkboxListRef"
				:translateCheckboxes="false"
				:data-testid="testIds?.['room-type-list']"
				:test-ids="testIds"
				v-if="!disabled"
				:items="readableRoomTypes"
				:get-child-props="(index: number) => getChildProps!(selectedRoomTypes[index].id)"
				:get-child="getChild"
				:maximum-selections-reached="maximumSelectionsReached"
				@change="onRoomTypeChange"
			/>
		</AnimationCollapse>
		<AnimationCollapse v-else>
			<CommonRadioButtonList
				ref="checkboxListRef"
				v-if="!disabled"
				:items="readableRoomTypes"
				:data-testid="testIds?.['room-type-list']"
				:translateCheckboxes="false"
				:test-ids="testIds"
				:get-child-props="(index: number) => getChildProps!(selectedRoomTypes[index].id)"
				:get-child="getChild"
				@change="onRoomTypeChange"
			/>
		</AnimationCollapse>
	</div>
</template>
